import "../css/header.css";
import React, { useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBagShopping } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../Component/CartContext";

function Header() {
  const navigate = useNavigate();

  const { cartItems } = useContext(CartContext);

  return (
    <div className="header-container">
      <div className="allDiv">
        <div className="headerDiv">
          <div className="leftUU">
            <ul className="leftUl">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/contact">Contact Us</a>
              </li>
              <li>
                <a href="/aboutUs">About Us</a>
              </li>
            </ul>
          </div>
          <div className="center">
            <a href="/">THE UK'S NO.1 BEST-RATED WIG STORE!</a>
          </div>
        </div>
        <div className="headerMid">
          <div className="headImg">
            <a href="/">
              <img alt="" src="/hairsImg/header1.png" />
            </a>
          </div>
          <div className="headSvg">
            <a href="/cartpage">
              <FontAwesomeIcon icon={faBagShopping} />
              <span className="cart-count">{cartItems.length}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
