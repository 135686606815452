import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/bottom.css";

function AboutUs() {
  return (
    <div className="main-container">
      <Header />
      <div className="helpDiv">
        <div className="helpUs">
          <h1>Help & Support for Hair-loss</h1>
          <span className="data">July 6, 2024</span>
        </div>

        <div className="refundMid">
          <img src="/footImg/image.png" alt="" />
          <div className="helpTxt">
            <p>
              At The Wonderful Wig Company, we have a deep understanding of the
              difficulties people with hair-loss can face through our founder’s
              own experience with cancer-related hair loss to the experiences of
              our friends, family and clients.
            </p>
            <p>
              Generally, losing your hair isn’t anything to worry about. It’s a
              natural part of ageing and can be affected by our genetics with
              the average person losing 50-100 hairs a day. It’s estimated that
              around 70% of women over the age of 70 experience female pattern
              baldness – the most common type of hair loss, which is thought to
              be inherited. Sometimes, however, it can be the result of a
              medical condition or illness, and it can come in a number of
              forms; partial loss, full loss, temporary or permanent.
            </p>
            <p>
              For women, losing your hair can be particularly difficult, with
              many becoming less social, giving up activities they enjoy, and
              even questioning whether their partner will still love them or
              find them attractive…
            </p>
            <h4>You’re not alone!</h4>

            <p>
              All of these questions, concerns and worries are perfectly normal,
              and there are a huge range of services available to help you
              answer them, overcome them, feel confident and be yourself again.
            </p>
            <p>
              If your hair-loss is due to a medical condition, your first point
              of support is of course your doctor or healthcare provider. Most,
              particularly the NHS, will be able to point you in the direction
              of some incredible experts and support groups.
            </p>
            <h3>It’s good to talk</h3>
            <p>
              First off, <strong>we’re here for you.</strong> Whether you’re a
              client or not, we’re here to answer your questions and point you
              in the right direction whenever you need. Our wonderful team are
              available online, by email and phone to support you and arrange a{" "}
              <strong>free consultation</strong> in our salon or via Skype or
              FaceTime if you’d rather. Simply get in touch and we’ll take it
              from there.
            </p>
            <p>
              There are also some amazing support groups throughout the UK with
              people facing exactly the same issues as you. Alopecia UK is just
              one of them. Visit their website to find your nearest support
              group where you can get answers to your questions, share your
              concerns and meet wonderful people going through the same thing.
            </p>
            <p>
              Most importantly –
              <strong> talk to your friends and family </strong>
              if you can. Sharing your concerns with those close to you can lift
              a great weight from your shoulders and theirs!
            </p>
            <p>
              If attending a group isn’t for you, you might want to try online.
              One such place is the Cancer Research forum. Here you can share
              your experiences and read others’, and even ask nurses any
              questions you might have.
            </p>
            <p>
              In addition, Cancer Research also have a huge range of information
              to help you talk with your friends and family if your hair-loss is
              cancer related. You might also find Macmillan’s guide to cancer
              hair-loss useful.
            </p>
            <h3>Avoid miracle cures</h3>
            <p>
              We’ve all seen them – oils, creams, lotions, potions and “ancient
              herbal remedies” – all promising to help re-grow hair and
              transform your life… but the reality is, these wonder products
              just don’t and won’t work.
            </p>
            <strong>
              That doesn’t mean you’re stuck being without your hair!
            </strong>
            <h3>
              Enter the wonderful world of wigs, hair pieces and headwear!
            </h3>
            <p>
              Wigs and hair pieces are a wonderful way to help you be you again.
              There are thousands of styles, colours and types available, and,
              in the unlikely event it’s not out there, we can make it!
            </p>
            <p>
              Whether you have partial hair-loss and just need a piece to give
              you that fuller look again or complete loss and need a wig,
              there’s something for you.
            </p>
            <p>
              Wigs have come a long way and with the right style and fit, you’d
              never you had one!
            </p>
            <p>
              If a wig isn’t for you, there’s a whole range of{" "}
              <strong>beautiful headscarves</strong> and hats designed
              specifically for those with hair-loss and cancer, using the
              softest natural linen and bamboo fabrics to feel amazing on your
              skin, keep you warm or cool and look stunning!
            </p>
            <p>
              If you’re an<strong> NHS patient</strong> and your hair-loss is
              medical, you can get a wig or hair-piece
              <strong> completely free.</strong> Unfortunately, we can’t
              currently accept NHS vouchers online but we can accept them in our
              salons. Online, you can spread the cost using our Payl8r option.
              As an <strong> NHS Approved Supplier, </strong>we can provide you
              with a range of information including about financing.
            </p>
            <p>
              If you’re not sure what’s right for you or want more information,
              our wonderfully talented, knowledgeable and empathetic team are
              available for<strong> free consultations </strong>in our salons,
              by email, telephone, Skype and FaceTime. Just get in-touch and
              we’ll do our best to help!
            </p>
            <p>
              If you’d prefer to visit a salon to discuss your needs and we’re
              not quite close enough, you can visit the MyNewHair website (of
              which we’re a<strong> certified salon</strong>) where you can
              search for a qualified new hair expert near you.
            </p>
            <h3>Free wigs for kids!</h3>
            <p>
              We’re pleased to be a Little Princess Trust provider, a charity
              who provide real hair wigs,{" "}
              <strong>completly free of charge,</strong> to boys and girls
              across the UK and Ireland that have lost their own hair through
              cancer treatment.
            </p>
            <p>Simply get in-touch and our wonderful team will help you.</p>
            <h3>We’re here for you</h3>
            <p>
              Whether you buy from us or not, <strong>our team are here</strong>{" "}
              to answer any questions you may have about hair-loss, wigs and
              headwear. You can contact us online through our live chat option,
              email, telephone, or visit us at one of our salons in Sunderland
              or Newcastle.
            </p>
            <br />
            <br />
            <hr />
            <h3>Other support resources</h3>
            <h4>NHS</h4>
            <p>A guide to hair loss, treatments and support from the NHS.</p>
            <h4>Alopecia UK</h4>
            <p>Working to improve the lives of those affected by Alopecia.</p>
            <h4>Macmillan Cancer Support</h4>
            <p>
              Providing physical, financial and emotional support to help people
              live life as fully as they can. Include a wealth of information
              about cancer-related hair loss.
            </p>
            <h4>Cancer Research UK</h4>

            <p>
              The world’s largest charity dedicated to saving lives through
              cancer research with a website packed full of information, advice
              and support options.
            </p>
            <h4>Look Good Feel Better</h4>
            <p>
              Look Good Feel Better (LGFB) is an international cancer support
              charity that helps boost the physical and emotional wellbeing of
              people undergoing cancer treatment through free confidence
              boosting workshops for women, men and young adults undergoing
              treatment for any type of cancer.
            </p>
            <h4>Little Princess Trust</h4>
            <p>
              Providing free real hair wigs to children and young people with
              hair loss, and funding vital research into childhood cancers.
            </p>
            <h4>MyNewHair</h4>
            <p>
              MyNewHair is a charity founded by and inspired by Trevor Sorbie
              MBE, providing advice and support through a national network of
              independent salons and professionals who provide a wig styling
              service for people suffering from cancer and medical hair loss.
            </p>
            <h4>British Association of Dermatologists</h4>
            <p>
              Advice for people living with skin conditions including alopecia.
            </p>
            <div className="helpIcons">
              <div className="iconTxt">
                <img alt="" src="/faceIcon/face.png" />
                SHARE
              </div>
              <div className="iconTxt">
                <img alt="" src="/faceIcon/Twir.png" />
                TWEET
              </div>
              <div className="iconTxt">
                <img alt="" src="/faceIcon/insta.png" />
                PIN IT
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mightDiv">
        <h2>You might like…</h2>
        <div class="parent-div">
          <div class="child-div">
            <img alt="" src="/footImg/help1.png" />
            <h2>A Closer Look at the Wigs in Barbie Movies</h2>
            <span>September 27, 2023</span>
            <p>
              Barbie movies have been captivating audiences worldwide with their
              enchanting stories, sparkling animation, and of course, the iconic
              fashion. Amo...
            </p>
          </div>
          <div class="child-div">
            <img alt="" src="/footImg/help2.png" />
            <h2>Comparing Wigs and Toppers!</h2>
            <span>June 29, 2023</span>
            <p>
              Comparing Wigs and Toppers! Many people consider these options for
              enhancing their hair, whether for fashion, medical reasons, or
              simply to change...
            </p>
          </div>
          <div class="child-div">
            <img alt="" src="/footImg/help3.png" />
            <h2>Wigs and the Trans Community</h2>
            <span>June 16, 2023</span>
            <p>
              In today's diverse and inclusive world, the journey of
              self-discovery and self-expression is celebrated and respected.
              For members of the transgen...
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AboutUs;
