import React, { useContext, useState } from "react";
import { CartContext } from "../Component/CartContext";
import "../css/checkout.css";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import ReactFlagsSelect from "react-flags-select";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

const CheckOut = () => {
  const { cartItems } = useContext(CartContext);
  const [country, setCountry] = useState("US");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [selected, setSelected] = useState("US");

  const StyledTableContainer = styled(TableContainer)({
    minWidth: 600,
  });

  const StyledImage = styled("img")({
    width: 112,
    height: 124,
    objectFit: "cover",
  });

  const StyledTableCell = styled(TableCell)({
    display: "flex",
    alignItems: "center",
    verticalAlign: "middle",
    fontSize: "16px",
    borderRight: "1px dotted #ccc",
  });

  const handlePlaceOrder = () => {
    const newErrors = {};

    if (!email) newErrors.email = "Email is required";
    if (!country) newErrors.country = "Country is required";
    if (!phone) newErrors.phone = "Phone number is required";
    if (!firstName) newErrors.firstName = "First name is required";
    if (!lastName) newErrors.lastName = "Last name is required";
    if (!address) newErrors.address = "Address is required";
    if (!city) newErrors.city = "City is required";
    if (!postalCode) newErrors.postalCode = "Postal code is required";

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      alert("Order placed!");
      navigate("/paysuccess");
    }
  };

  const isFormValid = () => {
    return (
      email &&
      country &&
      phone &&
      firstName &&
      lastName &&
      city &&
      postalCode &&
      address
    );
  };

  const getProductById = (id) => {
    return productData.find((product) => product.id === id);
  };

  const calculateTotal = () => {
    return cartItems.reduce((total, item) => {
      const product = getProductById(item.id);
      const price = parseFloat(product.price.replace("£", ""));
      return total + price * item.quantity;
    }, 0);
  };

  return (
    <>
      <div className="main-container">
        <Header />
        <div className="check-custom-checkout-page">
          <div className="left">
            <div className="check-order-info">
              <h2>Billing Information</h2>
              <div>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email (for sending your order receipt):"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {errors.email && <p className="error">{errors.email}</p>}
              </div>

              <ReactFlagsSelect
                selected={selected}
                onSelect={(code) => {
                  setSelected(code);
                  setCountry(code);
                }}
                className="check-select"
              />

              {errors.selected && <p className="error">{errors.selected}</p>}
              <div>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder="First name:"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                {errors.firstName && (
                  <p className="error">{errors.firstName}</p>
                )}
              </div>
              <div>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  placeholder="Last name:"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
                {errors.lastName && <p className="error">{errors.lastName}</p>}
              </div>
              <div>
                <input
                  type="text"
                  id="address"
                  name="address"
                  placeholder="Address:"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
                {errors.address && <p className="error">{errors.address}</p>}
              </div>
              <div>
                <input
                  type="text"
                  id="city"
                  name="city"
                  placeholder="City:"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
                {errors.city && <p className="error">{errors.city}</p>}
              </div>
              <PhoneInput
                country={country.toLowerCase()}
                value={phone}
                onChange={(number) => setPhone(number)}
                className="check-input"
                style={{ width: "95.3%" }}
              />

              {errors.phone && <p className="error">{errors.phone}</p>}
              <div>
                <input
                  type="text"
                  id="postalCode"
                  name="postalCode"
                  placeholder="Postal code:"
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                />
                {errors.postalCode && (
                  <p className="error">{errors.postalCode}</p>
                )}
              </div>
              <button
                className="check-checkout-button"
                onClick={handlePlaceOrder}
                disabled={!isFormValid()}
              >
                Pay Now
              </button>
            </div>
          </div>

          <div className="right">
            <div className="check-order-summary">
              <h2>Order Summary</h2>
              <div className="cart-items">
                {cartItems.length === 0 ? (
                  <h3 style={{ color: "black", textAlign: "center" }}>
                    Your Cart is Empty!
                  </h3>
                ) : (
                  <StyledTableContainer component={Paper}>
                    <Table aria-label="shopping cart table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Item</TableCell>
                          <TableCell align="left">Price</TableCell>
                          <TableCell align="left">Color</TableCell>
                          <TableCell align="left">Qty</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {cartItems.map((item, index) => {
                          const product = getProductById(item.id);

                          return (
                            <TableRow key={index}>
                              <StyledTableCell component="th" scope="row">
                                <StyledImage
                                  src={product.images[0]}
                                  alt="Product"
                                  style={{ paddingRight: "30px" }}
                                />
                                {product.name}
                              </StyledTableCell>
                              <TableCell
                                align="left"
                                style={{
                                  verticalAlign: "middle",
                                  borderRight: "1px dotted #ccc",
                                }}
                              >
                                {product.price}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  verticalAlign: "middle",
                                  borderRight: "1px dotted #ccc",
                                }}
                              >
                                {item.color}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  verticalAlign: "middle",
                                  borderRight: "1px dotted #ccc",
                                }}
                              >
                                {item.quantity}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </StyledTableContainer>
                )}
              </div>
              {cartItems.length > 0 && (
                <div className="cart-summary">
                  <div className="rightSum">
                    <div className="summary-details">
                      <p className="grayP">Total Items: {cartItems.length}</p>
                      <p className="grayP">
                        Subtotal : GBP
                        <span style={{ fontWeight: "500", color: "black" }}>
                          {" "}
                          £{calculateTotal().toFixed(2)}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default CheckOut;
