import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useNavigate, Link } from "react-router-dom";

import "../css/main.css";
import productData from "../Datas/productData";

function Main() {
  const navigate = useNavigate();
  const [hoverImg, setHoverImg] = useState(null);

  const enterMouse = (hoverImg) => {
    setHoverImg(hoverImg);
  };

  const leaveMouse = () => {
    setHoverImg(null);
  };

  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="twoDiv">
          <img src="/hairsImg/hairMain.jpg" alt="twoImg" />
        </div>
        <div className="firstDescription">
          <div className="firstTxt">
            <p>
              If you're looking for premium quality, women's and ladies wigs
              made in the UK, then you've come to the right place. Here at the
              Wonderful Wig Company, we're based in the North East of England
              and specialise in offering premium quality wigs made from human
              hair. Our wigs are all made{" "}
            </p>
            <p>
              Looking for the perfect ladies wig? Shop our full collection of
              wigs at the Wonderful Wig Company in this collection. We stock
              thousands of wigs from the world's leading wig brands to ensure
              that there is plenty of choice for anyone who is looking for their
              perfect wig. Despite stocking a huge range of wigs, each has been
              hand selected by our expert team of stylists based in the UK. Each
              has been picked based on its merits of style, comfort, quality and
              value. Whether you're looking for a short wig or long wig,
              straight, curly or wavy, we have the perfect one for you within
              our collection. It's always been our frustration that there is not
              enough choice out there on the market - particularly for those
              that feel that their hair gives them their confidence. As a key
              part of our identities, we hope to match as many people in the UK
              as possible with their perfect wig.<span>…Read more</span>
            </p>
          </div>
        </div>

        <div className="fiveDiv">
          <ul className="brand-shops">
            {productData
              .filter((brand) => brand.id >= 1 && brand.id <= 12)
              .map((brand, index) => (
                <li key={index}>
                  <Link
                    to={`/shopDetails/${brand.id}`}
                    onMouseEnter={() => enterMouse(brand.id)}
                    onMouseLeave={leaveMouse}
                  >
                    <img
                      alt="icon"
                      src={
                        hoverImg === brand.id
                          ? brand.images[1]
                          : brand.images[0]
                      }
                    />
                  </Link>
                  <div className="sixName">
                    <h3>{brand.name}</h3>
                  </div>
                  <span className="price-container">
                    <p className="original-price">{brand.preprice}</p>
                    <p className="discounted-price">{brand.price}</p>
                  </span>
                </li>
              ))}
          </ul>
        </div>

        
      </div>
      <Footer />
    </div>
  );
}

export default Main;
