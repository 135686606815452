import React, { useContext } from "react";
import { CartContext } from "../Component/CartContext";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import "../css/cartpage.css";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

const StyledTableContainer = styled(TableContainer)({
  minWidth: 650,
});

const StyledImage = styled("img")({
  width: 112,
  height: 124,
  objectFit: "cover",
});

const StyledDeleteIcon = styled(FontAwesomeIcon)({
  cursor: "pointer",
  textAlign: "center",
});

const StyledTableCell = styled(TableCell)({
  display: "flex",
  alignItems: "center",
  verticalAlign: "middle",
  fontSize: "16px",
  borderRight: "1px dotted #ccc",
});

const CartPage = () => {
  const { cartItems, removeFromCart } = useContext(CartContext);
  const navigate = useNavigate();

  const getProductById = (id) => {
    return productData.find((product) => product.id === id);
  };

  const toCheckOut = () => {
    if (cartItems.length === 0) {
      alert("Please add items to your cart before proceeding to checkout.");
      return;
    }
    navigate("/checkout");
  };

  const backToShop = () => {
    navigate("/");
  };
  const calculateTotal = () => {
    return cartItems.reduce((total, item) => {
      const product = getProductById(item.id);
      const price = parseFloat(product.price.replace("£", ""));
      return total + price * item.quantity;
    }, 0);
  };

  return (
    <>
      <div className="main-container">
        <Header />
        <div className="cart-page">
          <div className="cart-header">
            <h1 style={{ color: "black" }}>Your Shopping Cart</h1>
          </div>
          <div className="cart-items">
            {cartItems.length === 0 ? (
              <h3 style={{ color: "black", textAlign: "center" }}>
                Your Cart is Empty!
              </h3>
            ) : (
              <StyledTableContainer component={Paper}>
                <Table aria-label="shopping cart table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Item</TableCell>
                      <TableCell align="left">Price</TableCell>
                      <TableCell align="left">Color</TableCell>
                      <TableCell align="left">Qty</TableCell>
                      <TableCell align="left">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cartItems.map((item, index) => {
                      const product = getProductById(item.id);
                      return (
                        <TableRow key={index}>
                          <StyledTableCell component="th" scope="row">
                            <StyledImage
                              src={product.images[0]}
                              alt="Product"
                              style={{ paddingRight: "30px" }}
                            />
                            {product.name}
                          </StyledTableCell>
                          <TableCell
                            align="left"
                            style={{
                              verticalAlign: "middle",
                              borderRight: "1px dotted #ccc",
                            }}
                          >
                            {product.price}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              verticalAlign: "middle",
                              borderRight: "1px dotted #ccc",
                            }}
                          >
                            {item.color}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              verticalAlign: "middle",
                              borderRight: "1px dotted #ccc",
                            }}
                          >
                            {item.quantity}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ verticalAlign: "middle" }}
                          >
                            <StyledDeleteIcon
                              icon={faTrashCan}
                              size="lg"
                              onClick={() =>
                                removeFromCart(item.id, item.color)
                              }
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </StyledTableContainer>
            )}
          </div>

          {cartItems.length > 0 && (
            <div className="cart-summary">
              <div className="rightSum">
                <h2>Cart Summary</h2>
                <div className="summary-details">
                  <p className="grayP">Total Items: {cartItems.length}</p>
                  <p className="grayP">
                    Subtotal (Refresh page if discount is not triggered):
                  </p>
                  <span> £{calculateTotal().toFixed(2)}</span>
                  <p>Tax included and shipping calculated at checkout.</p>
                </div>
                <button className="checkout-button" onClick={toCheckOut}>
                  SECURE CARD CHECKOUT
                </button>
                <div className="checkout-style">
                  <img
                    alt=""
                    src="/cartCheck/cart.png"
                    className="check-cart-left"
                    onClick={toCheckOut}
                  />
                  <img alt="" src="/cartCheck/cart1.png" onClick={toCheckOut} />
                </div>
                <button className="continue-shop" onClick={backToShop}>
                  CONTINUE SHOPPING
                </button>
              </div>
            </div>
          )}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default CartPage;
